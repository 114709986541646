import { useEntity } from '@backstage/plugin-catalog-react';
import { Container } from '@material-ui/core';
import { stringifyEntityRef } from '@backstage/catalog-model';
import React from 'react';
import { PostsContainer } from '@drodil/backstage-plugin-qeta-react';

export const QetaContent = () => {
  const { entity } = useEntity();

  return (
    <Container>
      <PostsContainer
        entity={stringifyEntityRef(entity)}
        showTitle
        showAskButton
      />
    </Container>
  );
};