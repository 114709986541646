import { SvgIcon, SvgIconProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  mySvgStyle: {
    fillColor: theme.palette.primary.main
  }
}))

const SailPointLogo = (props: SvgIconProps) => {
  const classes = useStyles();
  return <SvgIcon {...props} viewBox="0 0 110 110">
    <path fill="#0033A1" d="M63,0l13.2,78.6H0L63,0z">
    </path>
    <path fill="#CC27B0" d="M62.9,0l46.7,78.6H76L62.9,0z">
    </path>
    <path fill="#0071CE" d="M0,78.6h76.2l4.8,28.4L0,78.6z">
    </path>
    <path fill="#E17FD2" d="M76,78.6h33.5L80.8,107L76,78.6z"></path>
  </SvgIcon>
};

export default SailPointLogo;