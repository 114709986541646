import { SvgIcon, SvgIconProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  mySvgStyle: {
    fillColor: theme.palette.primary.main
  }
}))

const AzureDevOpsLogo = (props: SvgIconProps) => {
  const classes = useStyles();
  return <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      className={classes.mySvgStyle}
      fill="#0072c6"
      d="M15 3.622v8.512L11.5 15l-5.425-1.975v1.958L3.004 10.97l8.951.7V4.005L15 3.622zm-2.984.428L6.994 1v2.001L2.382 4.356 1 6.13v4.029l1.978.873V5.869l9.038-1.818z" />
  </SvgIcon>
};

export default AzureDevOpsLogo;