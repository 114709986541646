import { SvgIcon, SvgIconProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  mySvgStyle: {
    fillColor: theme.palette.primary.main
  }
}))

const AzureLogo = (props: SvgIconProps) => {
  const classes = useStyles();
  return <SvgIcon {...props} viewBox="0 0 160 160">
    <path
      className={classes.mySvgStyle}
      fill="#0072c6"
      d="m88.33 0-47.66 41.33-40.67 73h36.67zm6.34 9.67-20.34 57.33 39 49-75.66 13h124z" />
  </SvgIcon>
};

export default AzureLogo;