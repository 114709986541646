import { SvgIcon, SvgIconProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  mySvgStyle: {
    fillColor: theme.palette.primary.main
  }
}))

const TerraformLogo = (props: SvgIconProps) => {
  const classes = useStyles();
  return <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      className={classes.mySvgStyle}
      fill="#7B42BC"
      d="M5.825 2.82l4.35 2.524v5.052L5.825 7.87V2.82zM10.651 5.344v5.052L15 7.87V2.82l-4.349 2.524zM1 0v5.05l4.349 2.527V2.526L1 0zM5.825 13.474L10.174 16v-5.051L5.825 8.423v5.051z" />
  </SvgIcon>
};

export default TerraformLogo;