import { TemplateBackstageLogoIcon, FeaturedDocsCard, HomePageCompanyLogo, HomePageToolkit, ComponentAccordion, HomePageRecentlyVisited, HomePageTopVisited } from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Content, Page, InfoCard } from '@backstage/core-components';
import LogoFull from '../Root/LogoFull';
import SNowLogo from './icons/SNowLogo';
import SailPointLogo from './icons/SailPointLogo';
import AzureLogo from './icons/AzureLogo';
import AzureDevOpsLogo from './icons/AzureDevOpsLogo';
import TerraformLogo from './icons/TerraformLogo';
import SonarqubeLogo from './icons/SonarqubeLogo';
import ESILogo from './icons/ESILogo';
import FortifyLogo from './icons/FortifyLogo';
import CyberarkLogo from './icons/CyberarkLogo';
import WizLogo from './icons/WizLogo';
import SnykLogo from './icons/SnykLogo';
import { AnnouncementsCard, NewAnnouncementBanner } from '@procore-oss/backstage-plugin-announcements';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none'
  }
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 200,
  },
  path: {
    fill: '#7df3e1',
  },
}));

const tools1 = [
  { url: 'https://avidxchange.service-now.com/sp?id=sc_category', label: 'Service Now', icon: <SNowLogo />, },
  { url: 'https://avidxchange.identitynow.com/ui/d/request-center/apps', label: 'Sailpoint', icon: <SailPointLogo />, }
];

const tools2 = [
  { url: 'https://portal.azure.com', label: 'Azure', icon: <AzureLogo /> },
  { url: 'https://dev.azure.com/avidxchange/', label: 'Azure DevOps', icon: <AzureDevOpsLogo /> },
  { url: 'https://app.terraform.io/', label: 'Terraform', icon: <TerraformLogo /> },
  { url: 'https://observe.avidxchange.com/', label: 'OP', icon: <TemplateBackstageLogoIcon /> },
  { url: 'https://sonarqube.avidxchange.com/', label: 'SonarQube', icon: <SonarqubeLogo /> },
  { url: 'https://esi.learnondemand.net/', label: 'ESI', icon: <ESILogo /> }
];

const tools3 = [
  { url: 'https://ams.fortify.com/', label: 'Fortify', icon: <FortifyLogo /> },
  { url: 'https://app.snyk.io/', label: 'Snyk', icon: <SnykLogo /> },
  { url: 'https://abd4132.id.cyberark.cloud/my?customerId=ABD4132', label: 'CyberArk', icon: <CyberarkLogo /> },
  { url: 'https://app.wiz.io', label: 'Wiz', icon: <WizLogo /> },
];

const tools4 = [
  { url: 'https://dev.azure.com/avidxchange/Architecture%20Center%20of%20Excellence/_wiki/wikis/Architecture-Center-of-Excellence.wiki/18505/AvidXchange-Official-Domain-Model', label: 'ODM Home', icon: <TemplateBackstageLogoIcon /> },
  { url: 'https://avidxchangedomainmodel.azurewebsites.net/', label: 'Arch Views', icon: <TemplateBackstageLogoIcon /> },
  { url: 'https://apps.powerapps.com/play/e/default-55d96f5e-2b75-4762-ae08-11de584d8e83/a/49c0d616-7eb2-4ee9-b89a-526d0a544c4f?tenantId=55d96f5e-2b75-4762-ae08-11de584d8e83', label: 'ARB Intake', icon: <TemplateBackstageLogoIcon /> },
  { url: 'https://dev.azure.com/avidxchange/Architecture%20Center%20of%20Excellence/_wiki/wikis/Architecture-Center-of-Excellence.wiki/18062/Standards', label: 'Standards', icon: <TemplateBackstageLogoIcon /> },
];

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();
  const ExpandedComponentAccordion = (props: any) => (
    <ComponentAccordion {...props} />
  );
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid container item xs={12}>
              <NewAnnouncementBanner />
            </Grid>
            <Grid container justifyContent="center" xs={6}>
              <HomePageCompanyLogo
                className={container}
                logo={<LogoFull height={256} />}
              />
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
              <HomePageSearchBar
                InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline } }}
                placeholder="Search"
              />
            </Grid>
            <br />
            <br />
            <br />
            <br />
            <Grid container xs={12}>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <FeaturedDocsCard filter={{
                    'spec.type': 'website',
                    'metadata.name': 'avid-developer-portal',
                  }} />
                </Grid>
                <Grid item xs={12}>
                  <HomePageTopVisited
                    numVisitsOpen={5}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HomePageRecentlyVisited
                    numVisitsOpen={5}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Grid item xs={12}>
                  <AnnouncementsCard max={4} />
                </Grid>
                <Grid item xs={12}>
                  <InfoCard title="Toolkit" noPadding>
                    <Grid item>
                      <HomePageToolkit title="IAM and Service Desk" tools={tools1} Renderer={ExpandedComponentAccordion} />
                      <HomePageToolkit title="Cloud and Delivery" tools={tools2} Renderer={ExpandedComponentAccordion} />
                      <HomePageToolkit title="Security" tools={tools3} Renderer={ExpandedComponentAccordion} />
                      <HomePageToolkit title="Architecture" tools={tools4} Renderer={ExpandedComponentAccordion} />
                    </Grid>
                  </InfoCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider >
  );
};