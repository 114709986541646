import React from 'react';
import AvidLogoWhite from './img/avid-logo-white.png';
import AvidLogoBlue from './img/avid-logo-blue.png';
import { useTheme } from '@material-ui/core';

const AvidLogo = ({ height }: { height: number }) => {
  const theme = useTheme();

  if (theme.palette.type === 'dark') {
    return <img src={AvidLogoWhite} height={height} />;
  } else {
    return <img src={AvidLogoBlue} height={height} />;
  }
}

export default AvidLogo;
