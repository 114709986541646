import { SvgIcon, SvgIconProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  mySvgStyle: {
    fillColor: theme.palette.primary.main
  }
}))

const CyberarkLogo = (props: SvgIconProps) => {
  const classes = useStyles();
  return <SvgIcon {...props} viewBox="2.42 30.58 63.764 56.044">
    <path d="M20.9 50.87l13.4-7.755V26.72L6.66 42.695z" fill="#5090ca" />
    <path d="M34.348 90.484L34.325 74.1l13.424-7.744 14.195-8.2V74.54zm-.058-47.37l13.424 7.744L61.9 42.66 34.3 26.72z" fill="#3374ae" />
    <path d="M34.325 74.1L20.9 66.357l-14.184 8.2 27.642 15.918z" fill="#5090ca" />
    <path d="M20.9 66.357V50.87L6.66 42.695l.045 31.87z" fill="#3374ae" />
    <path d="M34.3 43.114l13.424 7.744v15.5L34.3 58.613z" fill="#808184" />
    <path d="M34.3 43.114v15.5l-13.4 7.744V50.87l13.4-7.755" fill="#58595b" />
  </SvgIcon >
};

export default CyberarkLogo;