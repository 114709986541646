import { SvgIcon, SvgIconProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  mySvgStyle: {
    fillColor: theme.palette.primary.main
  }
}))

const AzureLogo = (props: SvgIconProps) => {
  const classes = useStyles();
  return <SvgIcon {...props} viewBox="0 0 130 65">
    <path
      className={classes.mySvgStyle}
      d="M38.7,37.2c0.1,0.2,0.4,0.2,0.4,0l7-18.2c0.3-0.9,1.2-1.4,2.1-1.4l6.5,0c1.2,0,2.3,1,2.3,2.2
      c0,0.3,0,0.5-0.1,0.8L41.1,63.1c0,0.1-0.5,0.5-0.9,0.8C40.1,64,39.9,64,39.7,64c-0.6-0.2-1.2-0.7-1.4-1.3l-8.4-20.3
      c-0.3-0.6-1-0.9-1.6-0.6c-0.2,0.1-0.5,0.3-0.6,0.6L17.1,63.5c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.1-0.1-0.1L0.2,20.5
      c-0.4-1.2,0.1-2.5,1.3-2.9c0.3-0.1,0.5-0.2,0.8-0.2h7.1c0.9,0,1.8,0.6,2.1,1.4l7,18.3c0.1,0.2,0.4,0.2,0.4,0L27.7,18
      c0.3-0.6,1-0.9,1.6-0.6c0.3,0.1,0.5,0.3,0.6,0.6L38.7,37.2z M119,0.2c-0.1-0.3-0.6-0.3-0.7,0c-0.4,2-1.3,5-2.9,6.6
      c-1.6,1.6-4.6,2.5-6.6,2.9c-0.3,0.1-0.3,0.6,0,0.7c2,0.4,5,1.3,6.6,2.9c2.1,2.1,2.8,5,3.1,6.7c0,0.1,0.4,0.1,0.4,0
      c0.4-1.8,1.2-5,2.9-6.7c1.6-1.6,4.6-2.5,6.6-2.9c0.3-0.1,0.3-0.6,0-0.7c-2-0.4-5-1.3-6.6-2.9C120.3,5.2,119.4,2.2,119,0.2z
       M59.5,20c0-1.2,1-2.3,2.3-2.3h6.8c1.2,0,2.3,1,2.3,2.3v41.7c0,1.2-1,2.3-2.3,2.3h-6.8c-1.2,0-2.3-1-2.3-2.3v0V20L59.5,20z
       M90.5,29.6c0.1-0.3,0-0.7-0.3-0.8c-0.1,0-0.2-0.1-0.3-0.1H76.5c-1.2,0-2.3-1-2.3-2.3c0,0,0,0,0,0V20c0-1.2,1-2.3,2.3-2.3h31.1
      c1,0,2.3,0.8,1.8,1.6l-18,33.6l0,0c-0.2,0.3,0,0.7,0.3,0.8c0.1,0,0.2,0.1,0.3,0.1h16.4c1.2,0,2.3,1,2.3,2.3v5.6
      c0,1.2-1,2.3-2.3,2.3h-33c-1.1,0-1.3-1.6-0.8-2.6C74.6,61.4,90.5,29.6,90.5,29.6z" />
  </SvgIcon>
};

export default AzureLogo;