import { SvgIcon, SvgIconProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  mySvgStyle: {
    fillColor: theme.palette.primary.main
  }
}))

const ESILogo = (props: SvgIconProps) => {
  const classes = useStyles();
  return <SvgIcon {...props} viewBox="0 0 30 30">
    <rect x="17" y="17" width="10" height="10" fill="#FEBA08" />
    <rect x="5" y="17" width="10" height="10" fill="#05A6F0" />
    <rect x="17" y="5" width="10" height="10" fill="#80BC06" />
    <rect x="5" y="5" width="10" height="10" fill="#F25325" />
  </SvgIcon>
};

export default ESILogo;